import { gql } from 'graphql-request';
import { useQuery, useQueryClient } from 'react-query';
import { IConversation } from '../../interfaces/conversation/conversation.model';
import { useApi } from '../api/useApi';
import { useConversationsLoaderState } from './state/conversationsLoader.state';
import { useMemo } from 'react';
import { useConsistencyUpdate } from './hooks/useConsistencyUpdate';

export interface IConversations {
    total: number;
    count: number;
    conversations: IConversation[];
}

export const useConversationsConsistencyQuery = () => {
    const { postGql } = useApi();
    const { storeLoaded } = useConversationsLoaderState();

    const { consistencyRemoveContracts, consistencyAddContractsToQueue } = useConsistencyUpdate();

    const queryClient = useQueryClient();

    const queryState = queryClient.getQueryState(['conversations-store']);

    const conversationsStoreIsFetching = useMemo(() => {
        return queryState?.fetchStatus === 'fetching';
    }, [queryState?.fetchStatus]);

    return useQuery({
        enabled: !conversationsStoreIsFetching && storeLoaded,
        queryKey: ['conversations-consistency'],
        queryFn: async () => {
            const take = 500; // Number of items to fetch per request
            let skip = 0; // Start fetching from index 0
            let allConversations: IConversation[] = [];
            let totalRecords = 0;

            do {
                // Fetch the next batch of conversations
                const { conversationsPaged } = await postGql<{
                    conversationsPaged: IConversations;
                }>(
                    gql`
                        query conversationsPaged($input: ConversationsPagedInput!) {
                            conversationsPaged(input: $input) {
                                total
                                count
                                conversations {
                                    id
                                    subject
                                    contractID
                                }
                            }
                        }
                    `,
                    {
                        input: {
                            take,
                            skip,
                        },
                    },
                    'conversations-consistency'
                );

                // Append results incrementally into the state
                allConversations = [...allConversations, ...conversationsPaged.conversations];

                // Update totalRecords for ending the loop condition#
                totalRecords = conversationsPaged.total;

                // Increment the offset (skip) for the next fetch
                skip += take;

                const updatedConversations = [...allConversations];

                queryClient.setQueryData<IConversations | undefined>(
                    ['conversations-consistency'],
                    (oldData) => {
                        return {
                            ...(oldData || { total: 0, count: 0, conversations: [] }),
                            conversations: updatedConversations,
                        };
                    }
                );
            } while (allConversations.length < totalRecords);

            return allConversations;
        },

        onSuccess: (conversations) => {
            consistencyRemoveContracts(conversations);
            consistencyAddContractsToQueue(conversations);
        },
        // 4 minutes
        staleTime: 60 * 4,
    });
};
