import {
    IConversationInput,
    IConversationsInput,
} from '../../interfaces/conversation/conversation.args';
import { IConversation } from '../../interfaces/conversation/conversation.model';
import { QueryClient } from 'react-query';
import { IConversationMessage } from '../../interfaces/conversation/convesationMessage.model';

export const conversationKeys = {
    all: ['conversation'] as const,
    list: (input: IConversationsInput) =>
        [...conversationKeys.all, 'list', input.archived] as const,
    listFilter: () => [...conversationKeys.all, 'list'] as const,
    single: (input: IConversationInput) => [...conversationKeys.all, 'single', input.id] as const,
    singleFilter: (conversationId: string) =>
        [...conversationKeys.all, 'single', conversationId] as const,
};

export const messageOnSuccess = (
    queryClient: QueryClient,
    conversationId: string,
    conversationMessage: IConversationMessage
) => {
    void queryClient.setQueryData(
        conversationKeys.single({
            id: conversationId ?? '',
        }),
        (old?: IConversation) => {
            if (old) {
                const messages = [...old.messages];
                messages.push(conversationMessage);
                return { ...old, messages };
            }
        }
    );
    void queryClient.setQueryData(
        conversationKeys.list({
            messagesSince: '',
            archived: false,
        }),
        (old?: IConversation[]) => {
            if (old) {
                const convos = [...old];
                const conversationIndex = convos.findIndex((convo) => convo.id === conversationId);
                if (conversationIndex && old[conversationIndex]) {
                    const conversation = {
                        ...convos[conversationIndex],
                    } as IConversation;
                    if (conversation.messages) {
                        const messages = [...conversation.messages];
                        messages.push(conversationMessage);
                        conversation.messages = messages;
                    } else {
                        conversation.messages = [conversationMessage];
                    }

                    convos[conversationIndex] = conversation;

                    return convos;
                }
            }
        }
    );
};
