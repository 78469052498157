import { IConversation } from '../../../interfaces/conversation/conversation.model';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';

export const filterConversations = (inputConversations: IConversation[], filterText: string) => {
    const fuse = new Fuse(inputConversations, {
        keys: [
            'id',
            'contractID',
            // 'subject',
            {
                name: 'subject',
                weight: 0.8,
            },
            {
                name: 'messages.body',
                getFn: (conv: IConversation): readonly string[] => {
                    return conv.messages
                        ?.map((msg) => msg.body)
                        .filter((body): body is string => typeof body === 'string');
                },
            },
            {
                name: 'messages.author.fields.firstName',
                getFn: (conv: IConversation): readonly string[] => {
                    return conv.messages
                        ?.map((msg) => msg.author?.fields?.firstName)
                        .filter((firstName): firstName is string => typeof firstName === 'string');
                },
            },
            {
                name: 'messages.author.fields.lastName',
                getFn: (conv: IConversation): readonly string[] => {
                    return conv.messages
                        ?.map((msg) => msg.author?.fields?.lastName)
                        .filter((lastName): lastName is string => typeof lastName === 'string');
                },
            },

            {
                name: 'messages.sent',
                getFn: (conv: IConversation): readonly string[] => {
                    return conv.messages
                        ?.map((msg) => {
                            if (!msg.sent) return ''; // Handle missing values safely
                            return dayjs(msg.sent).format('DD.MM.YYYY'); // e.g., "24.02.2025"
                        })
                        .filter((sent): sent is string => sent.length > 0);
                },
            },
        ],
        shouldSort: true,
        includeScore: true,
        threshold: 0.3,
        distance: 200,
        findAllMatches: false,
        minMatchCharLength: 1,
    });

    const fuzzed = fuse.search(filterText);

    const filtered: IConversation[] = [];
    fuzzed.forEach((item) => filtered.push(item.item));

    return filtered;
};
