import { create } from 'zustand';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { IConversationMessage } from '../../../interfaces/conversation/convesationMessage.model';

interface IConversationsLoader {
    conversations: IConversation[];
    conversationsFiltered: IConversation[];
    setConversations: (state: IConversation[]) => void;
    setConversationsFiltered: (state: IConversation[]) => void;
    addMessageToConversation: (conversationId: string, message: IConversationMessage) => void;
    storeLoaded: boolean;
    setStoreLoaded: (state: boolean) => void;
    skip: number;
    take: number;
    setSkip: (state: number) => void;
    setTake: (state: number) => void;
    addSkip: (state: number) => void;
    set: (state: Partial<IConversationsLoader>) => void;
    getConversationById: (conversationId: string) => IConversation | undefined;
    conversationsToUpdate: number[];
    setConversationsToUpdate: (state: number[]) => void;
    lastSyncTimestamp?: string | null;
    setLastSyncTimestamp: (state: string) => void;
}

export const useConversationsLoaderState = create<IConversationsLoader>((set, get) => ({
    conversations: [],
    conversationsFiltered: [],
    storeLoaded: false,
    setStoreLoaded: (state) => {
        set({ storeLoaded: state });
    },
    setConversations: (state) => {
        set({ conversations: state });
    },
    setConversationsFiltered: (state) => {
        set({ conversationsFiltered: state });
    },
    addMessageToConversation: (conversationId, message) => {
        const { conversations } = get();
        const updatedConversations = conversations.map((conversation) => {
            if (conversation.id === conversationId) {
                return {
                    ...conversation,
                    messages: [...conversation.messages, message],
                };
            }
            return conversation;
        });
        set({ conversations: updatedConversations });
    },
    skip: 0,
    take: 500,
    addSkip: (state) => {
        set({ skip: get().skip + state });
    },
    setSkip: (state) => {
        set({ skip: state });
    },
    setTake: (state) => {
        set({ take: state });
    },
    set: (state) => {
        set(state);
    },
    getConversationById: (conversationId) => {
        const { conversations } = get();
        return conversations.find((conversation) => conversation.id === conversationId);
    },
    conversationsToUpdate: [],
    setConversationsToUpdate: (state) => {
        set({ conversationsToUpdate: state });
    },
    lastSyncTimestamp: null,
    setLastSyncTimestamp: (state) => {
        set({ lastSyncTimestamp: state });
    },
}));
