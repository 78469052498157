import { useConversationsLoaderState } from '../../../hooks/conversation/state/conversationsLoader.state';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { useMemo } from 'react';
import { filterConversations } from '../helper/filterConversations';
import { useConversationState } from '../recoil/conversationState.state';

export const useFilteredConversations = () => {
    const { filterText } = useConversationState();

    const { conversations } = useConversationsLoaderState();

    const conversationsFiltered: IConversation[] = useMemo(() => {
        if (!filterText && filterText.trim() === '') return conversations;

        return filterConversations(conversations, filterText);
    }, [conversations, filterText]);

    return { conversationsFiltered };
};
