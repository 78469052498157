import { useEffect } from 'react';
import { useConversationsConsistencyQuery } from '../../../hooks/conversation/useConversationsConsistencyQuery';

export const useConsistencyRefreshService = () => {
    const { isFetching, refetch } = useConversationsConsistencyQuery();

    useEffect(() => {
        const interval = setInterval(() => {
            if (isFetching) {
                return;
            }

            void refetch();
        }, 300000);

        return () => {
            clearInterval(interval);
        };
    }, [isFetching, refetch]);
};
