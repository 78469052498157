import { IConversation } from '../../../interfaces/conversation/conversation.model';
import dayjs from 'dayjs';

const getLatestMessageTimestamp = (conversation: IConversation) => {
    if (!conversation || !conversation.messages || conversation.messages.length === 0) {
        return null;
    }

    return conversation.messages
        .filter((message) => message.sent) // Filter out messages without a `sent` timestamp
        .map((message) => dayjs(message.sent)) // Map to `dayjs` objects for easier comparison
        .sort((timeA, timeB) => timeB.diff(timeA))[0]; // Sort descending and take the first (newest)
};

export const conversationSortByNewestMessage = (
    conversations: IConversation[]
): IConversation[] => {
    if (!conversations) {
        return [];
    }

    return conversations?.sort((convA, convB) => {
        // Get the newest `sent` timestamp from each conversation
        const latestMessageA = getLatestMessageTimestamp(convA);
        const latestMessageB = getLatestMessageTimestamp(convB);

        // Compare the two timestamps
        if (latestMessageA && latestMessageB) {
            return latestMessageB.diff(latestMessageA); // Newer message comes first
        }

        // Handle missing timestamps
        if (latestMessageA) return -1; // `convA` has a timestamp, `convB` doesn't
        if (latestMessageB) return 1; // `convB` has a timestamp, `convA` doesn't

        return 0;
    });
};
