import { useChatMessageQueue } from '../recoil/chatMessageQueue.state';
import { useCallback, useEffect, useMemo } from 'react';
import { IMessageInQueue } from '../interfaces/messageInQueue.interface';
import { useConversationsLoaderState } from '../../../hooks/conversation/state/conversationsLoader.state';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../Auth/userAtom';
import { useConversationState } from '../recoil/conversationState.state';
import { useQueryClient } from 'react-query';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { conversationKeys } from '../../../hooks/conversation/queryKeyFactory';

export const useChatMessageQueueService = () => {
    const { lastSync, setLastSync, setMessages } = useChatMessageQueue();

    const queryClient = useQueryClient();

    const { addMessageToConversation: addMessageConversationsList } = useConversationsLoaderState();

    const { addMessage: addMessageConversationState } = useConversationState();

    const user = useRecoilValue(userAtom);

    const messageQueue: IMessageInQueue[] = useMemo(() => {
        if (lastSync) {
            if (typeof window !== 'undefined') {
                const messagesLocal = JSON.parse(
                    window.localStorage.getItem('messageQueue') || '[]'
                ) as IMessageInQueue[];
                return messagesLocal;
            }
        }

        return [];
    }, [lastSync]);

    const sendMessage = useCallback(() => {
        // eslint-disable-next-line no-console

        if (messageQueue.length > 0) {
            messageQueue.forEach((message) => {
                // Update queryClient key ["conversation","single", conversationId] with optimistic update
                const conversationId = message.conversationId;

                const conversation: IConversation | undefined = queryClient.getQueryData([
                    'conversation',
                    'single',
                    conversationId,
                ]);

                if (conversation) {
                    queryClient.setQueryData(conversationKeys.singleFilter(conversationId), {
                        ...conversation,
                        messages: [
                            ...conversation.messages,
                            {
                                id: message.tempMessageId,
                                message: message.body,
                                sent: null,
                                body: message.body,
                                author: {
                                    ...user,
                                },
                            },
                        ],
                    });
                }

                addMessageConversationState({
                    id: message.tempMessageId,
                    subject: '',
                    unreadCount: 0,
                    address: '',
                    company: '',
                    lastMessage: {
                        body: message.body,
                        sent: null,
                        author: {
                            name: 'Ich',
                            origin: 'self',
                        },
                    },
                    contractExpress: false,
                    contractId: 'Test',
                    recipients: [],
                });

                // Optimistic update so that Preview are working and search
                addMessageConversationsList(message.conversationId, {
                    id: message.tempMessageId,
                    author: {
                        ...user,
                    },
                    body: message.body,
                    sent: undefined,
                    read: [],
                    deleted: false,
                    received: [],
                    automated: false,
                });
            });

            // Reset
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('messageQueue', JSON.stringify([]));
            }
            setMessages([]);
        }
    }, [
        addMessageConversationState,
        addMessageConversationsList,
        messageQueue,
        queryClient,
        setMessages,
        user,
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            sendMessage();
            setLastSync(new Date().toISOString());
        }, 1000);

        return () => clearInterval(interval);
    }, [sendMessage, setLastSync]);

    return {};
};
