import { create } from 'zustand';
import { IMessageInQueue } from '../interfaces/messageInQueue.interface';

interface IChatMessageQueue {
    lastSync: string;
    setLastSync: (lastSync: string) => void;
    messages: IMessageInQueue[];
    addMessage: (message: IMessageInQueue) => void;
    setMessages: (messages: IMessageInQueue[]) => void;
    set: (state: Partial<IChatMessageQueue>) => void;
}

export const useChatMessageQueue = create<IChatMessageQueue>((set) => ({
    lastSync: '',
    setLastSync: (lastSync) => {
        set({ lastSync });
    },
    setMessages: (messages) => {
        set({ messages });
    },
    messages: [],
    addMessage: (message) => {
        set((state) => {
            return { messages: [...state.messages, message] };
        });

        if (typeof window !== 'undefined') {
            const messages = JSON.parse(
                window.localStorage.getItem('messageQueue') || '[]'
            ) as IMessageInQueue[];
            window.localStorage.setItem('messageQueue', JSON.stringify([...messages, message]));
        }
    },
    set: (state) => {
        set(state);
    },
}));
