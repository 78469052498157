import { useCallback } from 'react';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { useQueryClient } from 'react-query';
import { useConversationsLoaderState } from '../state/conversationsLoader.state';

export const useConsistencyUpdate = () => {
    const {
        setConversations,
        conversations: conversationsStored,
        setConversationsToUpdate,
    } = useConversationsLoaderState();

    const queryClient = useQueryClient();

    const consistencyAddContractsToQueue = useCallback(
        (conversations: IConversation[]) => {
            // Extract all new conversation IDs3
            const newConversationIds: number[] = [];
            conversations.forEach((fetchedConvo) => {
                const existsInStore = conversationsStored.some(
                    (storedConvo) => storedConvo.id === fetchedConvo.id
                );
                if (!existsInStore) {
                    newConversationIds.push(Number(fetchedConvo.id));
                }
            });

            // console.log('newConversationIds', newConversationIds);

            // Schedule Update
            setConversationsToUpdate(newConversationIds);
        },
        [conversationsStored, setConversationsToUpdate]
    );

    const consistencyRemoveContracts = useCallback(
        (conversations: IConversation[]) => {
            // Step A: Remove IDs from conversationsUpdated that are not in `conversations`
            const conversationsUpdated = conversationsStored.filter((storedConvo) =>
                conversations.some((fetchedConvo) => fetchedConvo.id === storedConvo.id)
            );

            // console.log('conversationsStored', conversationsStored);
            // console.log('conversationsUpdated', conversationsUpdated);

            // Update
            setConversations(conversationsUpdated);
            queryClient.setQueryData<IConversation[]>(
                ['conversations-store'],
                conversationsUpdated
            );
        },
        [conversationsStored, queryClient, setConversations]
    );

    return { consistencyRemoveContracts, consistencyAddContractsToQueue };
};
