import React, { useMemo } from 'react';
import { MenuAlt2Icon } from '@heroicons/react/solid';
import { useSyncContext } from '../../../context/syncContext';
import { LogSync } from '../LogSync';
import { useSurveyPhotoSync } from '../../../hooks/useSurveyPhotoSync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CompatibilityModal } from '../../atoms/CompatibilityModal/CompatibilityModal';
import { faSync } from '@fortawesome/pro-duotone-svg-icons';
import { useContractPinnedSync } from '../../../hooks/useContractPinnedSync';
import { SurveyDateSync } from '../SurveyDateSync';
import { useRouter } from 'next/router';
import { ChatMessageIndicator } from '../../Chat/ChatMessageIndicator';

interface IScene {
    pageTitle?: string;
    sidebarOpen?: boolean;
    setSidebarOpen: (value: boolean) => void;
    backRoute?: string | null;
}

export const SceneHeader: React.FC<IScene> = ({ pageTitle, setSidebarOpen }) => {
    const [{ syncing }] = useSyncContext();

    const { asPath, push } = useRouter();

    useSurveyPhotoSync();
    useContractPinnedSync();

    const fontSizePageTitle = useMemo(() => {
        if (pageTitle && pageTitle?.length > 25) {
            return 'text-[10px] leading-[12px] sm:text-sm';
        }

        return 'text-xs sm:text-sm';
    }, [pageTitle]);

    const showBackButton = useMemo(() => {
        return typeof window !== 'undefined' && window.history.length >= 2 && asPath !== '/';
    }, [asPath]);

    const showBackButtonButNotOnRoot = useMemo(() => {
        return typeof window !== 'undefined' && window.history.length < 2 && asPath !== '/';
    }, [asPath]);

    return (
        <div className="gradientPrimary sticky top-0 z-10 flex h-10 w-screen flex-shrink-0 shadow">
            <CompatibilityModal />

            <div className="flex w-full flex-row items-center text-2xl font-semibold text-primary">
                {showBackButton && (
                    <button
                        className="flex h-full w-10 items-center justify-center"
                        onClick={() => window.history.back()}
                        data-test-id="navigationBackButton"
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="md:text-md text-xl text-white"
                        />
                    </button>
                )}

                {/* This Button shall be shown, we aren't on home and we don't have a history, yet */}
                {showBackButtonButNotOnRoot && (
                    <button
                        className="flex h-full w-10 items-center justify-center"
                        onClick={() => void push('/')}
                        data-test-id="navigationBackButton"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="md:text-md text-xl" />
                    </button>
                )}

                <div
                    className={`relative mx-auto flex flex-row gap-1 font-serif uppercase tracking-wide text-white ${fontSizePageTitle}`}
                >
                    {pageTitle}
                    {syncing && (
                        <div className="absolute -right-14">
                            <FontAwesomeIcon
                                icon={faSync}
                                className={'mx-2 h-5 w-5 animate-spin'}
                            />
                        </div>
                    )}
                </div>
                <LogSync />
                <SurveyDateSync />
                <div className="relative flex flex-row gap-x-2">
                    <ChatMessageIndicator />
                    {/*<OnlineIndicatorDisplay />*/}
                </div>
            </div>
            <button
                type="button"
                className={`focus:ring-primary-500 border-r border-gray-200 px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset`}
                onClick={() => setSidebarOpen(true)}
            >
                <MenuAlt2Icon className="h-6 w-6" />
            </button>
        </div>
    );
};

SceneHeader.defaultProps = {
    backRoute: null,
};
